import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify/lib';
import router from './router';
import fr from 'vuetify/es5/locale/fr';
import 'vue-material-design-icons/styles.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import './registerServiceWorker'
import Notifications from 'vue-notification'

// import { Amplify } from 'aws-amplify';
// import awsExports from './aws-exports';
// Amplify.configure( awsExports );

Vue.use(Vuetify);
Vue.use(Notifications)

let vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
  theme: {
    themes: {
      light: {
        primary: '#003E5C',
        secondary: '#91BE1E',
        accent: '#FFD205',
        error: '#EF7D00',
      },
    },
  },
});


Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app');
