<template>
  <div>
    <div v-if="$route.name == 'Login'" class="bg"></div>

    <v-snackbar v-model="snackbar">
      Vos données ont été mise à jour
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fermer</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarRooms">
      Les chambres de l'établissement ont été mise à jour dans le Manager
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarRooms = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>

    <v-app id="app">
      <v-app-bar id="appBar" color="white" :height="60" app elevate-on-scroll>
        <img @click="$router.push('/')" width="140" height="60"
          src="https://www.santementale.fr/medias/2021/03/korian.jpeg" />
        <v-spacer></v-spacer>

        <div v-if="connected && $route.name != 'Login'">
          <v-btn @click="dialog = true" class="ma-2" color="teal white--text" dark>
            <v-icon class="pa-2">mdi-bell-ring</v-icon>
            Envoyer une notification
          </v-btn>

          <v-dialog v-model="dialogEditManager" transition="dialog-bottom-transition" fullscreen hide-overlay>
            <v-card class="pa-4" elevation="2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-toolbar class="text-h5" flat dark color="primary">
                  <v-toolbar-title>
                    <v-icon class="ma-1">mdi-account</v-icon> Modifier le
                    Manager
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialogEditManager = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-row class="pa-4">
                  <v-col cols="4">
                    <v-text-field append-icon="mdi-account-circle" v-model="managerEdit.name"
                      :rules="[rules.required, rules.min]" label="Nom du Manager" hint="Nom du responsable Manager"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="selectEtb" :items="itemsEtbs" outlined
                      :item-text="(item) => `${item.infos.name}`" persistent-hint single-line return-object outlined
                      label="Selectionner un etablissement"></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field append-icon="mdi-email" v-model="managerEdit.email"
                      :rules="[rules.required, rules.email]" hint="E-mail de connexion" label="E-mail du Manager"
                      required></v-text-field>
                  </v-col>

                  <v-card color="primary" dark class="pa-4" width="600">
                    <v-card-title>
                      <v-icon>mdi-lock</v-icon> Modifier le mot de
                      passe
                    </v-card-title>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field type="password" append-icon="mdi-lock" :rules="[rules.required, rules.password]"
                          v-model="managerEdit.password" label="Mot de passe du Manager" hint="minimum 8 caractères"
                          required></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field type="password" append-icon="mdi-lock" :rules="[rules.password]"
                          v-model="managerEdit.repassword" label="Reconfirmer le mot de passe"
                          hint="minimum 8 caractères" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
                <v-card-actions class="float-right">
                  <v-btn large color="red" outlined dark @click="deleteUser">
                    <v-icon>mdi-delete</v-icon> Supprimmer ce Manager
                  </v-btn>

                  <v-btn :disabled="!valid" @click="editUser" class="primary" elevation="2">
                    <v-icon class="pa-1">mdi-pencil</v-icon>Modifier ce
                    Manager
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogManager" transition="dialog-bottom-transition" fullscreen hide-overlay>
            <v-card class="pa-4" elevation="2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-toolbar class="text-h5" flat dark color="primary">
                  <v-toolbar-title>
                    <v-icon class="ma-1">mdi-account</v-icon> Ajouter un
                    Manager
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialogManager = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-row class="pa-4">
                  <v-col cols="4">
                    <v-text-field append-icon="mdi-account-circle" v-model="manager.nom"
                      :rules="[rules.required, rules.min]" label="Nom du Manager" hint="Nom du responsable Manager"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field append-icon="mdi-account-circle" v-model="manager.prenom"
                      :rules="[rules.required, rules.min]" label="Prénom du Manager" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="selectEtb" :items="itemsEtbs" outlined :item-text="
                      (item) => `${item.infos.name} - ${item.infos.city}`
                    " persistent-hint return-object single-line outlined label="Selectionner un etablissement">
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field append-icon="mdi-email" v-model="manager.email" :rules="[rules.required, rules.email]"
                      hint="E-mail de connexion" label="E-mail du Manager" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field type="password" append-icon="mdi-lock" :rules="[rules.required, rules.password]"
                      v-model="manager.password" label="Mot de passe du Manager" hint="minimum 8 caractères" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field type="password" append-icon="mdi-lock" :rules="[rules.password, rules.repass]"
                      v-model="manager.repassword" label="Reconfirmer le mot de passe" hint="minimum 8 caractères"
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn :disabled="!valid" @click="sendUser" large block color="primary">
                    <v-icon class="pa-1">mdi-plus</v-icon>Ajouter ce
                    Manager
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" transition="dialog-bottom-transition">
            <v-card elevation="2" style="padding: 10px" color="primary darken-2" dark>
              <v-card-title>
                <v-icon class="ma-1">mdi-message</v-icon> Message de
                l'annonce
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-alert dark color="pink"><strong>Attention pas de notification possible entre 20h00 et 6h00</strong></v-alert>
                </v-col>
                <v-col cols="12">
                <v-radio-group v-model="modeNotification">
                  <v-radio
                      label="Silencieuse"
                      value="silence"
                    ></v-radio>
                    <v-radio
                      label="Lu automatiquement"
                      value="automatic"
                    ></v-radio>
                    </v-radio-group>

                    </v-col>
                    <hr />

                    <ul>
                  <li>Silencieuse : Tous les appareils produiront un bruit et une lumière jaune pour indiquer une notification. Le résident devra demander « quelle sont les notifications » et Alexa énoncera le message.
                    Si elle n’est pas lu, la notification disparait au bout d’une heure</li>
                  <li>Lu automatiquement : Le texte sera énoncé par Alexa sur toutes les appareils, sans notification</li>
                </ul>
                <v-col cols="12">
                  <v-textarea class="pa-2" prepend-icon="mdi-check" label="Votre annonce Alexa" v-model="annonce"
                    hint="Ecrivez une annonce pour les enceintes Alexa"></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn :disabled="!isValidHours" @click="sendMessage" large block color="primary">
                  <v-icon class="pa-1">mdi-send</v-icon>Envoyer ce
                  message
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn @click="add" color="pink" class="white--text" filled v-if="user.admin === true">
            <v-icon>mdi-plus</v-icon> Ajouter un établissement
          </v-btn>
          <v-btn v-if="connected && $route.name != 'Login'" icon>
            <v-icon @click="logout">mdi-logout</v-icon>
          </v-btn>
        </div>
      </v-app-bar>
      <notifications group="foo" />

      <v-navigation-drawer v-if="connected && $route.name != 'Login'" app>
        <v-list-item @click="$router.push('/')">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Manager Alexa
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-badge dot class="ma-2" right color="success"></v-badge>
              Bonjour {{ user.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-chip v-if="user.admin === true" color="pink" label text-color="white">
          Administrateur
        </v-chip>
        <v-chip class="ma-2" color="success" outlined>
          <v-icon left> mdi-home </v-icon>
          {{ user.etb }}
        </v-chip>
        <v-divider></v-divider>

        <v-list-item class="mt-8" v-if="user.admin === true">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Changer de Manager
            </v-list-item-title>
            <v-list-item-content>
              <v-select v-model="select" :items="items" :item-text="(item) => `${item.name} - ${item.etb}`"
                :hint="`${select.name}, ${select.etb}`" persistent-hint return-object single-line @change="changeUser"
                outlined label="Selectionner"></v-select>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mt-8" v-if="user.admin === true">
          <v-list-item-content>
            <v-btn elevation="2" @click="dialogEditManager = true" class="pa-2" rounded large color="info">
              <v-icon>mdi-pencil</v-icon> Modifier ce manager
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mt-8" v-if="user.admin === true">
          <v-list-item-content>
            <v-btn @click="dialogManager = true" elevation="2" class="pa-2" rounded large color="primary">
              <v-icon>mdi-plus</v-icon> Ajouter un manager
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  class="mt-8">
          <v-list-item-content>
            <v-btn @click="syncDevices" elevation="2" class="pa-2" rounded large color="pink white--text"
              :loading="loadingSync">
              <v-icon>mdi-sync</v-icon> Synchroniser
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list v-if="connected">
          <v-list-item @click="$router.push('/')" class="px-2">
            <v-list-item-avatar>
              <v-img @click="$router.push('/')" :width="20"
                src="https://www.korian.fr/assets/images/default_thumbnail.png">
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click="goto('menu')">
            <v-list-item-icon>
              <v-icon>mdi-food</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Les Menus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="goto('animations')">
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Les Animations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="goto('services')">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Les Services</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="goto('informations')">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Les Informations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<style>
.opa {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script>
import xhr from "./plugins/axios";
import { v4 as uuidv4 } from "uuid";
import "./App.scss";
const email = /^\S+@\S+\.\S+$/;
const long = /^.{3,}$/;
export default {
  name: "Login",
  data() {
    return {
      modeNotification: 'silence',
      snackbarRooms: false,
      valid: true,
      rules: {
        required: (value) => !!value || "Obligatoire.",
        min: (value) => (value && value.length > 3) || "Pas assez long",
        password: (value) => (value && value.length > 7) || "Pas assez long",
        email: (value) => email.test(value) || "E-mail non valide",
        long: (value) => long.test(value) || "Non valide",
        repass: (value) =>
          (value && value.length > 7 && this.manager.password == value) ||
          "Confirmation non valide",
      },
      user: {},
      manager: {
        id: uuidv4(),
        nom: "",
        prenom: "",
        email: "",
        password: "",
        property: "",
        admin: false,
      },
      items: [],
      rooms: [],
      selectEtb: null,
      loadingSync: false,
      itemsEtbs: [],
      dialog: false,
      connected: localStorage.getItem("user"),
      snackbar: false,
      annonce: "",
      select: "",
      dialogManager: false,
      dialogEditManager: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name == "Edit") {
        this.snackbar = true;
      }
    },
  },
  created() {
    if (localStorage.getItem("rooms")) {
      this.rooms = JSON.parse(localStorage.getItem("rooms"));
    }

    this.user = localStorage.getItem("user");
    if (this.user) {
      this.user = JSON.parse(this.user);
      this.select = this.user;
    }

    if (this.user) {
      this.managerEdit = {
        id: this.user._id,
        name: this.user.name,
        etb: this.user.etb,
        etbId: this.user.etbId,
        email: this.user.email,
        password: "",
      };
    }
    xhr
      .get("/load-etbs")
      .then((res) => {
        this.itemsEtbs = Object.values(res.data);
        if(this.managerEdit){
          this.selectEtb = this.itemsEtbs.find(
            (e) => e.id === this.managerEdit.etbId
          );
        }
        
      })
      .catch((e) => console.log(e));
    xhr
      .get("/load-users")
      .then((res) => {
        this.items = res.data;
      })
      .catch((e) => console.log(e));
  },
  computed: {
    isValidHours(){
      let dt = new Date()
      let hour =  + dt.getHours(); 
      return hour >= 6 && hour < 20
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    syncDevices() {
      this.loadingSync = true;
      const user = JSON.parse(this.connected);
      xhr
        .post("/loadRooms", {
          org: "amzn1.alexa.unit.did.AFNYVSQ3V6HVB5WQRP6PJCPTICXSUWMMVUBYLJQXQY4ILM3M634I33A5TCYIGG4M4MZCHPL6SFGSVMYRFSW4VWZG6YK5KWUOZQJYIZ3D",
          prop: "amzn1.alexa.unit.did.AGO4GLFOR7ITQKJUMXC74FSKWJQ2V5KIEPVNMYEB5RJQILGB6IA2JUMD73R4G7VDNSSFD6XZFLNHHBL2YNSO7Z6GQG2N74LQEVRONWJH",
        })
        .then(async (resultat) => {
            console.log(user)
            resultat.data.rooms.map((e) => console.log(e.name))
          // console.log(resultat.data.rooms)
          // xhr
          //   .post("/refreshRooms", {
          //     rooms: resultat.data.rooms,
          //     prop: user.property,
          //   })
          //   .then(async (res) => {
              // this.snackbarRooms = true;
              // this.loadingSync = false;
              // localStorage.setItem("prop", JSON.stringify(resultat.data.prop));
              // localStorage.setItem(
              //   "rooms",
              //   JSON.stringify(resultat.data.rooms)
              // );
              // window.location.reload();
            });
        // });
          
    },
    changeUser(val) {
      localStorage.setItem("etbId", val.etbId);
      val.admin = true;
      localStorage.setItem("user", JSON.stringify(val));
      xhr
        .post("/loadRooms", { org: val.organisationId, prop: val.property })
        .then((resultat) => {
          localStorage.setItem("prop", JSON.stringify(resultat.data.prop));
          localStorage.setItem("rooms", JSON.stringify(resultat.data.rooms));
          if (this.admin === true) {
            window.location = "/add";
          } else {
            window.location = "/";
          }
        });
    },
    deleteUser() {
      xhr
        .post("/delete-user", { id: this.managerEdit.id })
        .then((res) => {
          this.dialogEditManager = false;
          window.location = "/";
        })
        .catch((e) => console.log(e));
    },
    editUser() {
      console.log(this.managerEdit)
      xhr
        .post("/edit-user", { manager: this.managerEdit, etb : this.selectEtb})
        .then((res) => {
          this.dialogEditManager = false;
          localStorage.setItem("prop", JSON.stringify(this.selectEtb));
          setTimeout(() => {
            window.location = "/";
          }, 2000);
        })
        .catch((e) => console.log(e));
    },
    sendUser() {

      xhr
        .post("/store-user", { manager: this.manager, etb: this.selectEtb })
        .then((res) => {
          this.dialogManager = false;
          this.manager = {
            id: uuidv4(),
            nom: "",
            prenom: "",
            email: "",
            password: "",
            property: "",
            admin: false,
          };
        })
        .catch((e) => console.log(e));
    },
    sendMessage() {
     let dt = new Date();
     let hour =  + dt.getHours(); 
     console.log(hour, "hour");
      if( hour >= 6 && hour < 20){

      if (window.confirm("Souhaitez-vous envoyer le message aux résidents ?")) {
        console.log(this.rooms, "rooms");
        this.dialog = false;
        xhr
          .post("/sendNotif", { message: this.annonce, rooms: this.rooms,   mode: this.modeNotification })
          .then((res) => {
            console.log("Notification envoyée");
            this.dialog = false;
          });
        }
      } else{
        alert("Les notifications ne sont pas autorisées entre 20h. et 6h.")
      }
    },

    goto(refName) {
      const element = document.getElementById(refName);
      // element.scrollIntoView();
      this.$vuetify.goTo(element);
      // goTo(refName);
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    add() {
      this.$router.push("/add");
    },
  },
};
</script>


