import axios from 'axios';

const username = process.env.VUE_APP_USERNAME
const password = process.env.VUE_APP_PASSWORD
const token = `${username}:${password}`;
const encodedToken = Buffer.from(token).toString('base64');

console.log(process.env);
const xhr = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    headers: { 'Authorization': 'Basic ' + encodedToken }
});

// JWT Web Token

// xhr.defaults.headers.common = {'Authorization': `bearer ${ localStorage.getItem('token')}`}

// Add a response interceptor
xhr.interceptors.response.use(
    response =>
    // Do something with response data
    response,
    error => {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default xhr;